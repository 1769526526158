import React from "react"
import { useLocation, useNavigate } from "@reach/router"
import { Link, navigate } from "gatsby"

enum EAuthPageName {
  Register = "register",
  Login = "login",
}

const LoginLayout = ({ children }) => {
  const location = useLocation()
  const pathName = location.pathname.split("/")[1]

  return (
    <div className="min-h-screen flex flex-col">
      <div className="grid grid-cols-1 grid-rows-1 min-h-screen">
        <div
          className="flex flex-col items-center"
          style={{
            background: "linear-gradient(73.29deg, #7C53FF 0%, #F796FF 100%)",
          }}
        >
          {children}
          {pathName === EAuthPageName.Register && (
            <div className="text-grey-0 inter-base-regular pb-2  flex gap-2">
              <div>You already have an account?</div>{" "}
              <div
                style={{ color: "inherit" }}
                onClick={() => navigate("/login")}
              >
                Login
              </div>
            </div>
          )}
          {pathName === EAuthPageName.Login && (
            <div className="text-grey-0 inter-base-regular pb-2  flex gap-2">
              You don't have an account?{" "}
              <div
                style={{ color: "inherit" }}
                onClick={() => navigate("/register")}
              >
                Register
              </div>
            </div>
          )}
          <div className="text-grey-0 inter-base-regular pb-12">
            © Mommi Commerce <span>&#183;</span>{" "}
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href="mailto:hello@medusajs.com"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginLayout
